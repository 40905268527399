<template>
    <div class="flex flex-col p-3 pt-6 sm:p-6">
        <Khead/>
        <div class="flex justify-center"
        :class="codeVerify ? 'mb-32': ''">
            <div class=" mb-10 bg-white sm:w-frame  border mt-8 rounded"
            :class="!codeVerify ? 'mb-64': ''"> 
                <KsCodeVerification v-if="!codeVerify"/>
                <KsCreateAccount v-else/>
            </div>
        </div>
    </div>
</template>

<script>
import KsCreateAccount from '../../components/AuthComponents/KsCreateAccount';
import KsCodeVerification from '../../components/AuthComponents/KsCodeVerification';
import Khead from '@/components/partials/Khead'

export default {
    data(){
        return{
            
        }
    },
    
    components: {
        KsCreateAccount,
        KsCodeVerification,
        Khead
    },
    computed: {
        codeVerify(){
            return this.$store.getters.getCodeVerify;
        }
    },
    methods: {
        
    },
    mounted() {
        this.$store.commit('mutCodeVerify', false)
    },
}
</script>

<style scoped>

</style>