<template>
    <div  class="p-6 md:p-10">
        <div class=" flex items-center justify-center">
            <Iconsign/>
            <span class="text-2xl font-semibold">{{ $t("sign.title") }}</span>
        </div>

        <form @submit.prevent="gotoStep2" data-vv-scope="form-1" v-show="step1">
            <div class="mt-4">
                <label class="text-base" for="name">{{ $t("sign.lastname") }}</label>
                <br>
                <input 
                    class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                    v-validate="'required'"
                    id="name" 
                    name="name" 
                    type="text"
                    v-model="lastname"
                    :class="{ ' border-tex-2': submitted1 && errors.has('form-1.name') }">
                    <span v-show="submitted1 && errors.has('form-1.name')" class="text-tex-2">{{ $t("sign.requireField") }}</span>
            </div>

            <div class="mt-4">
                <label class="text-base" for="surname">{{ $t("sign.surname") }}</label>
                <br>
                <input 
                    class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                    v-validate="'required'"
                    id="surname" 
                    name="surname" 
                    type="text"
                    v-model="surname"
                    :class="{ 'border-tex-2': submitted1 && errors.has('form-1.surname') }">
                    <span v-show="submitted1 && errors.has('form-1.surname')" class="text-tex-2">{{ $t("sign.requireField") }}</span>
            </div>

            <div class="mt-4">
                <label class="text-base" for="email">Email</label>
                <br>
                <input 
                    class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                    v-validate="'required|email'"
                    id="email" 
                    name="email" 
                    type="text"
                    v-model="email"
                    :class="{ ' border-tex-2': submitted1 && errors.has('form-1.email') }"
                    :disabled="this.$store.state.userSign.email.length>0"
                    :readonly="this.$store.state.userSign.email.length>0">
                    <span v-show="submitted1 && errors.has('form-1.email')" class="text-tex-2">{{ $t("sign.errorMail") }}</span>
                    <br><i v-show="existingMail" class="text-tex-2">{{ $t("sign.existingMail") }}</i>
            </div>

            <div class="mt-4">
                <label class="text-base" for="phone">{{ $t("rechargeSuccess.phone") }}</label>
                <br>
                <div v-if="this.$store.state.userSign.phone.length>0" class=" bg-gray-200 select-none h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row justify-between items-center"
                :class="{ ' border-tex-2': submitted1 && errors.has('form-1.phone') }">
                    <span class="p-1">(+{{ countryCode }})</span>
                    <input 
                        class="w-full rounded leading-tight focus:outline-none focus:bg-white" 
                        v-validate="'required|numeric|min:8'"
                        id="phone" 
                        name="phone" 
                        maxlength="8"
                        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        type="number"
                        min="0"
                        disabled
                        v-model="phone"
                        :readonly="this.$store.state.userSign.phone.length>0">
                </div>
                <div v-if="this.$store.state.userSign.phone.length==0">
                    <vue-tel-input 
                        inputClasses=""
                        placeholder=''
                        name="phone" 
                        inputId="phone"
                        :enabledCountryCode="true"
                        :validCharactersOnly="true"
                        @country-changed="countryChanged"
                        wrapperClasses = "h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded"
                        v-model="phone">
                    </vue-tel-input>
                    <div v-show="phoneError" class="flex items-center mt-1">
                        <IconError/><span class="ml-1 text-tex-2">{{ $t("sign.errorNumber") }}</span>
                    </div>
                </div>
                <i v-show="existingPhone && !phone " class="text-tex-2">{{ $t("sign.existingNumber") }}</i>
            </div>

            <button class="focus:outline-none mt-6 text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded" >
                <div >{{ $t("sign.nextBtn") }}</div>
            </button>
        </form>

        <form  @submit.prevent="isEnterprise" data-vv-scope="form-2" v-show="step2">
            <div class="mt-4 text-center font-semibold text-xl">{{ $t("sign.officeTitle") }}</div>
            <div class="mt-2 text-center">{{ $t("sign.officeSubtitle") }}</div>
            <div class="mt-4">
                <label class="text-base" for="officeName">{{ $t("sign.officeName") }}</label>
                <br>
                <input 
                    class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                    v-validate="'required'"
                    id="officeName" 
                    name="officeName" 
                    type="text"
                    v-model="enterprise.name"
                    :class="{ ' border-tex-2': submitted2 && errors.has('form-2.officeName') }">
                    <span v-show="submitted2 && errors.has('form-2.officeName')" class="text-tex-2">{{ $t("sign.requireField") }}</span>
            </div>

            <div class="mt-4">
                <label class="text-base" for="officePhone">{{ $t("rechargeSuccess.phone") }}</label>
                <br>
                <div >
                    <vue-tel-input 
                        inputClasses=""
                        placeholder=''
                        name="phone" 
                        inputId="phone"
                        :enabledCountryCode="true"
                        :validCharactersOnly="true"
                        @country-changed="enterpriseCountryChanged"
                        wrapperClasses = "h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded"
                        v-model="enterprise.phone">
                    </vue-tel-input>
                    <div v-show="enterprisePhoneError" class="flex items-center mt-1">
                        <IconError/><span class="ml-1 text-tex-2">{{ $t("sign.errorNumber") }}</span>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <label class="text-base" for="officeEmail">Email</label>
                <br>
                <input 
                    class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                    v-validate="'required|email'"
                    id="officeEmail" 
                    name="officeEmail" 
                    type="text"
                    v-model="enterprise.email"
                    :class="{ ' border-tex-2': submitted2 && errors.has('form-2.officeEmail') }">
                    <span v-show="submitted2 && errors.has('form-2.officeEmail')" class="text-tex-2">{{ $t("sign.errorMail") }}</span>
            </div>

            <div class="mt-4">
                <label class="text-base" for="logo">{{ $t("sign.officeLogo") }}</label>
                <br>
                <input 
                    class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                    
                    id="logo" 
                    name="logo" 
                    type="file"
                    accept="image/*"
                    @change="onFileChanged"
                    >
            </div>

            <span class="mt-6 flex items-center ">
                <button type="button" @click="isParticular"
                    class=" border text-kbutton-1 border-kbutton-1 h-minput  hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 w-1/2 text-sm sm:text-base rounded focus:outline-none"
                    >{{ $t("sign.ignoreBtn") }}</button>
                <button type="submit"
                    class="ml-2 text-white border h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 w-1/2 text-sm sm:text-base rounded focus:outline-none"
                    >{{ $t("sign.confirmBtn") }}</button>
                    
            </span>

            
        </form>

        <form @submit.prevent="submit" data-vv-scope="form-3" v-show="step3">
            <div class="mt-4 text-center">{{ $t("sign.pwdConfig") }}</div>
            <div class="mt-4">
                <label class="text-base " for="pwd">{{ $t("sign.pwd") }}</label>
                <br>
                <div class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                :class="{ ' border-tex-2': submitted && errors.has('form-3.pwd') }">
                    <input 
                        class="pl-3 w-11/12 leading-tight focus:outline-none focus:bg-white " 
                        v-validate="'required|min:8|number|charac|upCase'"
                        id="pwd" 
                        name="pwd"
                         :type="pwdShow ? 'text' : 'password'"
                        v-model="password"
                        ref="pwd">
                    <Iconeye 
                        class="m-2" 
                        @click="pwdShow = !pwdShow"
                        v-if="!pwdShow"/>
                    <Iconeyeoff 
                        class="m-2" 
                        @click="pwdShow = !pwdShow"
                        v-if="pwdShow"/>
                </div>
                <span v-show="submitted && errors.has('form-3.pwd')" class="text-tex-2">{{ $t("sign.errorPwd") }}</span>
            </div>

            <div class="grid grid-rows-2 grid-flow-col gap-2 mt-3" v-if="password.length !=0 && !isValidPwd">
                <div class="flex flex-row items-center"><Icononline v-if="validMsg.carac"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.caractere") }}</span></div>
                <div class="flex flex-row items-center"><Icononline v-if="validMsg.num"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.chiffre") }}</span></div>
                <div class="flex flex-row items-center"><Icononline v-if="validMsg.upper"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.lettre") }}</span></div>
                <div class="flex flex-row items-center"><Icononline v-if="validMsg.eightCarac"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.eightcar") }}</span></div>
            </div>

            <div class="mt-4">
                <label class="text-base " for="pwdconfirm">{{ $t("sign.pwdConfirm") }}</label>
                <br>
                <div 
                    class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                    :class="{ ' border-tex-2': submitted && errors.has('form-3.pwdconfirm') }">
                    <input 
                        class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white" 
                        id="pwdconfirm" 
                        name="pwdconfirm" 
                        :type="confirmPwdShow ? 'text' : 'password'"
                        v-validate="'required|confirmed:pwd'"
                        data-vv-as="pwd">
                    <Iconeye 
                        class="m-2" 
                        @click="confirmPwdShow = !confirmPwdShow"
                        v-if="!confirmPwdShow"/>
                    <Iconeyeoff 
                        class="m-2" 
                        @click="confirmPwdShow = !confirmPwdShow"
                        v-if="confirmPwdShow"/>
                </div>
            </div>


            <button type="submit" class="focus:outline-none mt-6 text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded" >
                <div v-show="!request">{{ $t("rechargeSuccess.receiveBtn") }}</div>
                <LoaderCss class="ml-16" v-show="request" />
            </button>
        </form>
        
    </div>
</template>

<script>
import axios from 'axios'
import LoaderCss from '@/components/LoaderCss'
import Iconsign from '../../assets/svg/icons/sign.svg?inline';
import Iconeye from '../../assets/svg/icons/eye.svg?inline';
import Iconeyeoff from '../../assets/svg/icons/eyeoff.svg?inline';
import Icononline from '../../assets/svg/icons/online.svg?inline';
import Iconoffline from '../../assets/svg/icons/offline.svg?inline';
import {VueTelInput} from 'vue-tel-input'
export default {
    data(){
        return{
            step1: true,
            step2: false,
            step3: false,
            lastname: '',
            surname: '',
            email: this.$store.state.userSign.email,
            password: '',
            phone: this.$store.state.userSign.phone,
            request: false,
            pwdShow: false,
            confirmPwdShow: false,

            enterprise: {
                name: '',
                phone: '',
                email: '',
                logo: null
            },

            validMsg: {
                carac: false,
                num: false,
                upper: false,
                eightCarac: false
            },
            submitted: false,
            submitted1: false,
            submitted2: false,
            existingMail: false,
            existingPhone: false,
            phoneError: false,
            countryCode: this.$store.state.userSign.countryCode,
            enterpriseCountryCode: null,
            enterprisePhoneError: false,
            
        }
    },
    
    components: {
        LoaderCss,
        Iconsign,
        Iconeye,
        Icononline,
        Iconoffline,
        Iconeyeoff,
        VueTelInput
    },
    watch: {
        password(value){
            this.password = value;
            this.validatePassword(value);
        },
        phone(){
            this.phoneError = false
        },
        enterprise:{
            handler(){
                 this.enterprisePhoneError = false
            },
            deep: true
        }
    },
    computed: {
       isValidPwd(){
           if (this.validMsg.carac && this.validMsg.num && this.validMsg.upper && this.validMsg.eightCarac) return true
           return false
       }
    },
    methods: {
        enterpriseCountryChanged(country){
            this.enterpriseCountryCode = country.dialCode
        },
        countryChanged(country) {
            this.countryCode = country.dialCode
        },
        onFileChanged (event) {
            const file = event.target.files[0];
            const formData = new FormData()
            formData.append('file', file)
            axios.post('/image-upload',formData)
            .then(res => {
                console.log(res)
                this.enterprise.logo = res.data
            })
            .catch(err => {
                console.log(err)
            })
        },
        
        gotoStep1(){
            this.step1 = true
            this.step2 = false
            this.step3 = false
        },
        isEnterprise(){
            this.enterprise.phone = this.enterprise.phone.replace(/\s+/g, '');
            if(this.enterprise.phone.length >= 8) {
                this.submitted2 = true;
                this.$validator.validateAll('form-2').then(valid => {
                    if (valid) {
                        this.step2 = false
                        this.step3 = true
                    }
                })
            }
            else {
                this.enterprisePhoneError = true
            }
        },
        isParticular(){
            this.enterprise = {
                name: null,
                phone: null,
                email: null,
                logo: null
            },
            this.step2 = false
            this.step3 = true
        },
        gotoStep2(){
            this.phone = this.phone.replace(/\s+/g, '');
            if(this.phone.length >= 8) {
                this.submitted1 = true;
                this.$validator.validateAll('form-1').then(valid => {
                    if (valid) {
                        this.step1 = false
                        this.step2 = true
                    }
                })
            }
            else {
                this.phoneError = true
            }
        },
        submit(){
            console.log(this.enterprise)
            this.existingPhone = false
            this.existingMail = false
            this.submitted = true;
            let registerByPhone
            if (this.$store.state.userSign.email.length>0) {
                registerByPhone = false
            }
            else {
                registerByPhone = true
            }
            this.$validator.validateAll('form-3').then(valid => {
            if (valid) {
                this.request = true
                if(this.enterprise.name != null){
                    axios.post('/users',{
                        firstName:this.surname,
                        lastName:this.lastname,
                        email:this.email,
                        telephone: this.phone,
                        countryCode: this.countryCode,
                        phoneWithCode: this.countryCode + this.phone,
                        office:{
                            name: this.enterprise.name,
                            email: this.enterprise.email,
                            countryCode: this.enterpriseCountryCode,
                            phone: this.enterprise.phone,
                            logo: this.enterprise.logo
                        },
                        registerByPhone: registerByPhone,
                        password: this.password,
                    })
                    .then(res => {
                        console.log(res)
                        this.$router.push("/recharges/signsuccess");
                        this.$store.commit('mutSubscribeByPhone', registerByPhone)
                        this.$store.commit('mutSignSuccess', true)
                    })
                    .catch(err => {
                        this.request = false
                        console.log(err.response.data)
                        const message = err.response.data.message;
                        this.gotoStep1()
                        if (message == 'phone already exist') this.existingPhone = true
                        else this.existingMail = true
                    })
                }
                else {
                    axios.post('/users',{
                        firstName:this.surname,
                        lastName:this.lastname,
                        email:this.email,
                        telephone: this.phone,
                        countryCode: this.countryCode,
                        phoneWithCode: this.countryCode + this.phone,
                        registerByPhone: registerByPhone,
                        password: this.password
                    })
                    .then(res => {
                        console.log(res)
                        this.$router.push("/recharges/signsuccess");
                        this.$store.commit('mutSubscribeByPhone', registerByPhone)
                        this.$store.commit('mutSignSuccess', true)
                    })
                    .catch(err => {
                        this.request = false
                        console.log(err.response.data)
                        const message = err.response.data.message;
                        this.gotoStep1()
                        if (message == 'phone already exist') this.existingPhone = true
                        else this.existingMail = true
                    })
                }
                
            }
            });
        },
        switchPwdType(){
            this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
        },
        validatePassword(value){
            let eigh = /.{8,}/;
            let numreq = /[0-9]+/;
            let upp = /[A-Z]+/;
            let car = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
            eigh.test(value) ? this.validMsg.eightCarac = true : this.validMsg.eightCarac = false;

            numreq.test(value) ? this.validMsg.num = true : this.validMsg.num = false;
            
            upp.test(value) ? this.validMsg.upper = true : this.validMsg.upper = false;
            
            car.test(value) ? this.validMsg.carac = true : this.validMsg.carac = false;
           
        }
    },
}
</script>

<style scoped>
.vue-tel-input {
    border-radius: 3px;
    display: flex;
    border: 1px solid #C6D6EC;
    text-align: left;
}

.vue-tel-input:hover{
    border-color: #A5BCE0;
}
.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: #4661B9;
}

.vue-tel-input:focus{
    outline: none !important;
    border-color: violet;
}

::v-deep .vti__dropdown,
::v-deep .vti__dropdown.open:focus,
::v-deep .vti__dropdown.open:focus-within  {
    outline-style: none;
    border-top-left-radius: 0.125rem/* 2px */;
    border-bottom-left-radius: 0.125rem/* 2px */;
}
</style>